import React, { useEffect, useState } from "react";
import { getSessionId } from "@amplitude/analytics-browser";
import useMediaQuery from "../../hooks/useMediaQuery";
import { isObjectEmpty, scrollBehaviorTo, windowIsExist } from "../../components/utils/index";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { ReactSVG } from "react-svg";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import { GetOrderHandler } from "../../services/api/GetOrder/GetOrderHandler";
import Loading from "../../components/atoms/Loading";
import { filterDataReview } from "../../services/componentSwitcher/filterDataReview";
import Button from "../../components/atoms/Button";
import Nav from "../../components/atoms/Nav";
import { GetApplicationNameHandler } from "../../services/api/GetApplicationName/GetApplicationNameHandler";
import { GetFormDetailsHandler } from "../../services/api/GetFormDetails/GetFormDetailsHandler";
import { buildAddons } from "../../components/utils/addons";
import OrderSummary from "../../components/organisms/OrderSummary";
import { ContactSummary } from "../../components/organisms/ContactSummary";
import { getUrlParams } from "../../components/utils";
import { getCookie, setCookies } from "../../components/utils/cookies";
import { GetApplicationInfoHandler } from "../../services/api/GetApplicationInfo/GetApplicationInfoHandler";
import { EditApplicationHandler } from "../../services/api/EditApplication/EditOrderHandler";
import { graphql, navigate } from "gatsby";
import Collapsible from "react-collapsible";
import { amplitudeTrackEvent, amplitudeTrackUser } from "../../components/utils/Amplitude";
import Alert from "../../components/atoms/Alert";
import AddonsField from "../../components/Form/AddonsField";
import FormSummary from "../../components/organisms/FormSummary";
import "./review.scss";

const Review = (props) => {
  const isMobile = useMediaQuery("mobile");
  const { orderId, pdfId } = getUrlParams();
  const { language } = useI18next();
  const { t } = useTranslation();
  const [isTestAB, setIsTestAB] = useState(false);
  const [state, setState] = useState({
    form: props.pageContext.json.fields,
    structure: props.pageContext.json.structure,
    data: {},
    applicationId: "",
    scrollTo: false,
    loading: false,
    pdfId: "",
    addons: [],
    price: 0,
    pdf: {},
  });

  // Test AB
  const updateAddonValues = (allAddons, activeAddons) => {
    if(activeAddons && allAddons) {
      // Get the list of identifiers for the active addons
      const activeIdentifiers = activeAddons.map(addon => addon.identifier);
            
      return allAddons.map(addon => ({
        ...addon,
        value: activeIdentifiers.includes(addon.identifier) ? "1" : "0",
        order: addon.order
      }));
    }
  }

  useEffect(() => {
    if (orderId && pdfId) {
      setCookies("address_token", {
        order: orderId,
        applicationId: orderId,
        pdfId: pdfId
      });
    }

    const orderCookie = getCookie("address_token");

    setState((prevState) => ({
      ...prevState,
      applicationId: orderCookie.applicationId,
      pdfId: orderCookie.pdfId,
    }));

    setScrollPage();

    if (orderCookie.applicationId) {
      fetchData(orderCookie.applicationId, props.pageContext.slug);
    } else {
      navigate(`/${props.pageContext.slug}`);
    }

    setCookies("app_id", { app_id: orderCookie.applicationId });
    setCookies("pdfId", { pdfId: orderCookie.pdfId });

    amplitudeTrackEvent("view_page_reviewPage", {
      form: props.pageContext.name.toLowerCase(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const setScrollPage = () => {
    if (state.scrollTo === false) {
      if (windowIsExist && window.location.hash !== "") {
        const hash = window.location.hash;
        document.querySelector(hash).scrollIntoView({ block: "start", behavior: "smooth" });
      } else {
        scrollBehaviorTo(0);
      }

      setState((prevState) => ({
        ...prevState,
        scrollTo: true,
      }));
    }
  };

  const fetchData = async (applicationId) => {
    const orderHandler = new GetOrderHandler({});
    const applicationNameHandler = new GetApplicationNameHandler({});
    const GetFormDetails = new GetFormDetailsHandler({});
    amplitudeTrackUser(applicationId);

    const data = {
      applicationId: applicationId,
    };

    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    let dataNameHandler = {};
    dataNameHandler.reference = applicationId;
    const getApplicationInfo = new GetApplicationInfoHandler({});

    try {
      const response = await getApplicationInfo.customAction(data);

      if (response.status === "registered") {
        const res = await Promise.all([
          orderHandler.customAction(data),
          applicationNameHandler.customAction(dataNameHandler),
          GetFormDetails.customAction({ formId: state.form.pdfId.value, addons: true }),
        ]);

        let form = {
          applicationName: res[1].application.formName,
          applicationNumber: res[1].application.formDescription,
        };

        setCookies("address_token", {
          reference: res[1].application.reference,
          paymentSite: res[1].application.paymentSiteWithPrefix,
          originalSite: res[1].application.originalSiteWithPrefix,
        });

        let formData = res[0].form;

        if (formData.addons && Array.isArray(formData.addons)) {
          const addons = buildAddons(formData.addons);
          formData.addons = addons.map((addon) => ({ ...addon, value: "1" }));
        }

        setState((prevState) => ({
          ...prevState,
          application: form,
          data: formData,
          loading: false,
          reference: res[1].application.reference,
          addons: updateAddonValues(buildAddons(res[2].addons), formData.addons),
          price: res[2].pdf.prices[0].amount,
          pdf: res[2].pdf,
        }));
      } else {
        navigate("/home");
      }
    } catch (error) {
      console.error(error);
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  let stepNames = [];
  state.structure.steps.map((step) => {
    let stepName = step.label.split(".")[1];
    stepNames.push(stepName);
    return stepNames;
  });

  const fieldsToHideInReview = [
    "emailAddressValidation",
    "removeAdditionalPhoneNumber1",
    "removeAdditionalPhoneNumber2",
    "hasChangedName",
    "firstNameIncorrectCheck",
    "middleNameIncorrectCheck",
    "lastNameIncorrectCheck",
    "dateOfBirthIncorrectCheck",
    "placeOfBirthIncorrectCheck",
    "genderIncorrectCheck",
  ];

  const sectionOpened = (step) => {
    if (windowIsExist) {
      return window.location.hash !== ""
        ? window.location.hash.replace("#", "") === step.name
        : state.structure.steps.indexOf(step) === 0;
    }
  };

  // PAS-6572 Test AB
  const languageIndex = {"en-US": "en", "es": "es"}

  useEffect(() => {
    const cookieTestAB = getCookie("testAB");
    if (cookieTestAB) setIsTestAB(cookieTestAB.testAB)
  }, [])

  const handleOnChange = (event) => {
    const addons = buildAddons(event.target.value);
  
    setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        addons: addons
      },
      addons: addons
    }));
  };
  
  const orderCookie = getCookie("address_token");

  const editApplication = async () => {
    const activeAddons = state.data.addons
      .filter(addon => addon.value === "1")
      .map(addon => addon.id);

    setState((prevState) => ({
      ...prevState,
      loading: true
    }));

    const newState = {
      data: {
        ...state.data,
        addons: activeAddons
      }
    };

    const editApplication = new EditApplicationHandler({});
    try {
      await editApplication.customAction({
        applicationId: orderCookie.applicationId,
        ...newState.data,
        language: languageIndex[language],
      });

      setState((prevState) => ({
        ...prevState,
        loading: false
      }));
    } catch (error) {
      console.error(error);
      setState((prevState) => ({
        ...prevState,
        loading: false
      }));
      throw error;
    }
  };

  // end PAS-6572 Test AB
  const submitApplicationContinue = () => {
    // PAS-6572 Test AB
    editApplication()
    // end PAS-6572 Test AB

    const orderCookie = getCookie("address_token");
    const tagCookie = getCookie("tag_param");
    const sessionId = getSessionId();

    if (orderCookie.paymentSite !== orderCookie.originalSite) {
      const redirect = `https://${orderCookie.paymentSite}/payment?order=${orderCookie.applicationId}&pdf=${orderCookie.pdfId}&session=${sessionId}&lang=${language}`;
      isObjectEmpty(tagCookie)
        ? (window.location.href = redirect)
        : (window.location.href = `${redirect}&tag=${tagCookie.tag}`);
    } else {
      navigate(`/payment`);
    }
  };

  return (
    <Layout
      hideLanguageSelector={true}
      headerTitle={
        props.pageContext.title[language] ? props.pageContext.title[language] : undefined
      }
      hideMenuButton={false}
      headerBigMaxWidth
      footerBigMaxWidth
      disclaimerBigMaxWidth>
      {state.loading && <Loading full />}
      <Nav
        currentStep={state.structure.steps.length}
        stepNames={stepNames}
        customStepNames={["Review Application"]}
      />
      <Wrapper twoCols>
        <div className="review">
          <div className={`review__wrapper u-box`}>
            <h1 className={`review__title extra-large`}>{t("Personal Application Review")}</h1>
            <p className={`review__subtitle big`}>
              {t("Review the data below and make changes if needed")}
            </p>
            {state.structure.steps.map((step, key) => {
              let groupTitle = t(step.label);
              let fields = {};
              if (key === 2 || key === 3)
                groupTitle = `${groupTitle.split("<div>")[0]} (${t("Optional")})`;
              let stepIsEmpty = true;
              return (
                <Collapsible
                  key={"group" + key + step.name}
                  trigger={
                    <>
                      <h2 className="review__item-title big">{t(groupTitle.split("<div>")[0])}</h2>
                      <div className="review__item-right">
                        <div className="review__item-status">
                          <span>{t("Completed")}</span>
                          <ReactSVG
                            src="/svg/check-with-circle.svg"
                            className="review__item-statusIcon"
                            title="review-status"
                          />
                        </div>
                        <ReactSVG
                          src="/svg/expand.svg"
                          className="review__item-expandIcon"
                          title="expand"
                        />
                      </div>
                    </>
                  }
                  className="review__item"
                  open={sectionOpened(step)}
                  openedClassName="review__item"
                  triggerClassName=""
                  triggerOpenedClassName=""
                  contentInnerClassName=""
                  containerElementProps={{ id: step.name }}
                  transitionTime={200}
                  triggerTagName="div">
                  {step.groups.map((group, index) => {
                    return !group.hiddenInReview ? (
                      <div
                        key={`step-${index}-${group.name}`}
                        id={`${group.name}${index}`}>
                        {group.fields.map((field, key) => {
                          if (state.data[field]) {
                            stepIsEmpty = false;
                          }
                          let question = state.form[field].reviewLabel
                            ? state.form[field].reviewLabel
                            : state.form[field].label;
                          let relative =
                            (field === "relative1Unknown" && t("Relative") + " 1") ||
                            (field === "relative2Unknown" && t("Relative") + " 2");
                          let response =
                            filterDataReview(state.form[field], state.data[field]) === undefined
                              ? ""
                              : filterDataReview(state.form[field], state.data[field]);

                          if (
                            response === "0" &&
                            (field === "relative2Unknown" || field === "relative1Unknown")
                          )
                            response = " ";
                          if (
                            response === "1" &&
                            (field === "relative2Unknown" ||
                              field === "relative1Unknown" ||
                              field === "changingGender")
                          )
                            response = "Yes";
                          if (field in fields) {
                            return false;
                          }
                          if (
                            fieldsToHideInReview.includes(field) ||
                            (response === "0" && field === "changingGender")
                          ) {
                            return false;
                          }
                          if (isTestAB && (field === "addons")) {
                            return false;
                          }
                          if (field === "addons") {
                            if (typeof state.data[field] !== "undefined") {
                              response = state.data[field].map((item) => t(item.title)).join(", ");
                            }
                          }
                          fields[field] = field;
                          return (
                            <div
                              className={`review__field`}
                              key={`${field.name}${key}`}>
                              {response.length !== 0 && (
                                <p className={`review__field__question medium`}>
                                  {question === "Unknown" ? `${relative}` : `${t(question)}`}
                                </p>
                              )}
                              {response.length !== 0 && (
                                <p className={`review__field__answer medium`}>
                                  {question === "Unknown" && response === "Yes"
                                    ? t(`Unknown`)
                                    : t(`${response}`)}
                                </p>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : null;
                  })}

                  {stepIsEmpty ? (
                    <p className="review__emptyCopy">
                      {t("For the moment you have not added any ")} {t(step.name.replace("-", " "))}
                    </p>
                  ) : null}

                  <div className={`edit__wrapper`}>
                    <Button
                      tertiary
                      dataQa={`edit-${step.name}`}
                      label={t(`Edit section`)}
                      link={`/edit/${props.pageContext.slug}/#${step.name}`}
                    />
                  </div>
                </Collapsible>
              );
            })}
            <Wrapper
              flex
              classNames={`review__footer`}>
              <div className={`disclaimer__wrapper`}>
                <Alert
                  className="disclaimer__wrapper__alert"
                  content={t(
                    "<b>Important:</b> Make sure all the data entered is correct before continue."
                  )}
                  showLeftIcon
                />
              </div>
              {isTestAB &&
                <div className="review__addons">
                  <AddonsField
                    onChange={handleOnChange}
                    errorMessage=""
                    type="array"
                    value={[]}
                    class=""
                    name="addons"
                    label="Select Add-ons"
                    tooltip=""
                    validate={false}
                    required={false}
                    addons={state.addons}
                  />
                </div>
              }
              {(!isMobile && isTestAB) &&
                <div>
                  <Button
                    primary
                    className="review__cta"
                    buttonId="submit-application"
                    dataQa={`submit-application`}
                    label={t(`Continue`)}
                    onClick={() => {
                      submitApplicationContinue();
                    }}
                  />
                </div>
              }
              {(!isTestAB) &&
                <div>
                  <Button
                    primary
                    buttonId="submit-application"
                    dataQa={`submit-application`}
                    label={t(`Continue`)}
                    onClick={() => {
                      submitApplicationContinue();
                    }}
                  />
                </div>
              }
            </Wrapper>
          </div>
        </div>
        <div className="review__summary">
          {(isMobile && isTestAB) ? (
            <div className="review__summary--testAB u-box u-box--small">
              <OrderSummary
                addons={state.addons}
                price={state.price}
                pdf={state.pdf}
                fixed
                form
                isOpen={true}
              />
              <Button
                primary
                buttonId="submit-application"
                dataQa={`submit-application`}
                label={t(`Continue`)}
                onClick={() => {
                  submitApplicationContinue();
                }}
              />
            </div>
            ) : (
                <OrderSummary
                  addons={state.addons}
                  price={state.price}
                  pdf={state.pdf}
                  fixed
                  form
                  isOpen={true}
                />
            )
          }
          {isTestAB &&
            <FormSummary
              pdf={state.pdf}
            />
          }
          <ContactSummary form />
        </div>
       
      </Wrapper>
    </Layout>
  );
};

export default Review;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
