import React, { useContext } from "react";
import { ReactSVG } from "react-svg";
import { graphql, useStaticQuery } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import RichText from "../RichText/richText";
import SiteDataContext from "../../../context/SiteDataContext";
import "./styles.scss";

const FormSummary = (props) => {
  const { language } = useI18next();
  const { t } = useTranslation();
  const pageQuery = useStaticQuery(contentfulFormTypeQuery);
  const data = language === "en-US" ? pageQuery.contentfulFormTypeEn : pageQuery.contentfulFormTypeEs
  const siteData = useContext(SiteDataContext);
  
  const formData = data.nodes
    .filter((data) => data.name === props.pdf.name)
    .map((formData) => formData.requeriments)[0];

  return (
    <div className="FormSummary u-box u-box--small">
      {Object.keys(props.pdf).length !== 0 && (
        <div className="FormSummary_Header">
            <div className="FormSummary_Icon">
            <ReactSVG
              src={`/svg/icon-form-${props.pdf.name.toLowerCase().replace("-", "")}.svg`}
              title={`${t(props.pdf.name)} icon`}
            />
          </div>
          <div className="FormSummary_Title">
            <div className="FormSummary_Title--main">
              <b>{t(`${props.pdf.description}`)}</b>
            </div>
            <div className="FormSummary_Title--subtitle">
              <b>{t(props.pdf.name)}</b>
            </div>
          </div>
        </div>
      )}
      <RichText
        content={formData}
        siteData={siteData}
      />
    </div>
  );
};

export default FormSummary;

const contentfulFormTypeQuery = graphql`
  query {
    contentfulFormTypeEn:allContentfulFormType(filter: { show: { eq: true }, node_locale: {eq: "en-US"} }) {
      nodes {
        name
        requeriments {
          raw
        }
      }
    }
    contentfulFormTypeEs:allContentfulFormType(filter: { show: { eq: true }, node_locale: {eq: "es"} }) {
      nodes {
        name
        requeriments {
          raw
        }
      }
    }
  }`;